import React, { useState, useEffect, useRef } from 'react';
import { useCountUp } from 'react-countup';

// components
import Navbar from '../../components/Navbar/Navbar';
import Footer from '../../components/Footer/Footer';

// images
import hero from './images/hero-desktop.jpg';
import heroDesktop from './images/hero-desktop.jpg';
import storyMobile from './images/story-mobile.jpg';
import service1 from './images/service1-mobile.jpg';
import service2 from './images/service2-mobile.jpg';
import service3 from './images/service3-mobile.jpg';
import footprintDesktop from './images/footprint-desktop.jpg';
import footprintMobile from './images/footprint-mobile.png';
import landingVideoCover from './images/landing-video-cover.png';
import play from './images/play.png';

interface ServiceI {
  img: string;
  title: string;
}

const services: Array<ServiceI> = [
  {
    img: service1,
    title: '空間規畫設計',
  },
  {
    img: service2,
    title: '工程統包規畫',
  },
  {
    img: service3,
    title: '企業、品牌規畫設計',
  },
];

interface feedback {
  time: string;
  desc: string;
  name: string;
}

const feedbacks: Array<feedback> = [
  {
    time: 'Jun 13, 2024',
    desc: '真的很謝謝羽匠設計師團隊的用心🥰，創造很棒的店內氛圍。',
    name: '驗光所 賴先生',
  },
  {
    time: 'Oct 02,2024',
    desc:
      '設計細心又專業，溝通順暢，讓我們的空間既實用又有質感！非常滿意這次的合作',
    name: '火鍋餐飲 張小姐',
  },
  {
    time: 'Feb 14,2024',
    desc: '從設計到施工都超用心！細節掌握得很好，空間質感大提升，真的很值得信賴！',
    name: '李公館 李先生',
  },
];

type Close = () => void;

const ModalCloseButton = ({ onClose }: { onClose: Close }) => {
  return (
    <div className="modal-close-button" onClick={onClose}>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="1em"
        height="1em"
        fill="currentColor"
        className="vbox-close-icon"
        viewBox="0 0 16 16"
      >
        <path
          fillRule="evenodd"
          d="M13.854 2.146a.5.5 0 0 1 0 .708l-11 11a.5.5 0 0 1-.708-.708l11-11a.5.5 0 0 1 .708 0Z"
        ></path>
        <path
          fillRule="evenodd"
          d="M2.146 2.146a.5.5 0 0 0 0 .708l11 11a.5.5 0 0 0 .708-.708l-11-11a.5.5 0 0 0-.708 0Z"
        ></path>
      </svg>
    </div>
  );
};

const VideoModal = ({ onClose }: { onClose: Close }) => {
  const iframeRef = useRef<HTMLIFrameElement>(null);
  const [height, setHeight] = useState(720);
  const ratio = 9 / 16;

  useEffect(() => {
    const handleResize = () => {
      if (iframeRef?.current?.offsetWidth !== undefined) {
        setHeight(iframeRef?.current?.offsetWidth * ratio);
      }
    };

    window.addEventListener('resize', handleResize);

    return () => window.removeEventListener('resize', handleResize);
  }, [ratio]);

  useEffect(() => {
    if (iframeRef?.current?.offsetWidth !== undefined) {
      setHeight(iframeRef?.current?.offsetWidth * ratio);
    }
  }, [ratio]);

  return (
    <div className="video-modal-wrapper" onClick={onClose}>
      <ModalCloseButton onClose={onClose} />
      <div className="video-content">
        <div className="iframe-wrapper">
          <iframe
            style={{
              height: height,
            }}
            ref={iframeRef}
            width="100%"
            height="100%"
            src="https://www.youtube.com/embed/-fUE3CM4WcQ?autoplay=1"
            title="形象官網"
            frameBorder="0"
            allow="autoplay"
            allowFullScreen
          ></iframe>
        </div>
      </div>
    </div>
  );
};

const VideoBlock = () => {
  const [isShowingModal, setIsShowingModal] = useState(false);

  const openModal = () => {
    document.body.classList.add('modal-open');
    setIsShowingModal(true);
  };

  const hideModal = () => {
    document.body.classList.remove('modal-open');
    setIsShowingModal(false);
  };

  return (
    <div className="video-block-wrapper">
      <div className="video-block">
        <div className="video-background">
          <img src={landingVideoCover} alt="形象影片" className="video-cover" />
        </div>
        <img src={play} alt="play" className="play" onClick={openModal} />
      </div>
      {isShowingModal && <VideoModal onClose={hideModal} />}
    </div>
  );
};

const Index = () => {
  let { countUp } = useCountUp({
    end: 768,
    duration: 30,
  });
  return (
    <>
      <Navbar />
      <div className="index-page">
        <div className="hero">
          <img src={hero} alt="yujames" className="mobile" />
          <img src={heroDesktop} alt="yujames" className="desktop" />

          <div className="content">
            <div className="sen1">我們到目前為止總共參與了</div>
            <div className="sen2">
              <div className="number">{countUp}</div>
              <div>件作品案例</div>
              <a href="/cases" className="desktop more">
                more—
              </a>
            </div>
          </div>
        </div>
        <div className="clearboth"></div>
        <VideoBlock />

        <div className="report">
          <h1>報導介紹｜INTERVIEWS</h1>
          <div className="report-block-wrapper">
            <div className="report-block">
              <div className="youtube-wrapper">
                <iframe
                  width="100%"
                  height="100%"
                  src="https://www.youtube.com/embed/_WFISX1iIP8"
                  title="YouTube video player"
                  frameBorder="0"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                  allowFullScreen
                ></iframe>
              </div>
              <div className="title">讓世界都看見-「羽匠空間設計」</div>
              <div className="description">
                將心比心從客戶角度出發，為商辦店面打造夢想藍圖，注入與業主共同創作的深度意義。
              </div>
            </div>
            <div className="report-block">
              <div className="story">
                <img src={storyMobile} alt="華品文化國際行銷-台灣百大品牌的故事(15)" />
              </div>
              <div className="title">華品文化國際行銷-台灣百大品牌的故事(15)</div>
              <div className="description">
                本書希望藉由文字力量，讓更多的人知曉在台灣土地上有一群為著理想、信念、勇氣持續努力的人。
              </div>
            </div>
          </div>
        </div>
        {/* service */}
        <div className="service">
          <h1>服務內容｜SERVICES</h1>
          <p>
            致力於服務商業空間、住宅空間、辦公空間...等各項需求之客戶，並且不論北中南、國外，皆有所服務之客戶，客戶的需求在哪裡，我們就會出現在哪裡
            。
          </p>
        </div>
        {/* design */}
        <div className="design">
          <ul>
            {services.map(({ img, title }) => {
              return (
                <li key={title}>
                  <div className="img-wrapper">
                    <img src={img} alt={title} />
                  </div>
                  <div className="title">{title}</div>
                  <button>
                    <a href="/service">進一步了解</a>
                  </button>
                </li>
              );
            })}
          </ul>
        </div>

        {/* others */}
        <div className="others">
          <div className="feedbacks">
            <h1>客戶的回饋是我們前進的良藥｜REVIEWS</h1>
          </div>
          <div className="container">
            <div className="flex">
              {feedbacks.map(({ time, desc, name }) => {
                return (
                  <div className="others-block" key={desc}>
                    <h3>{time}</h3>
                    <p>{desc}</p>
                    <p className="name">{name}</p>
                    <button>
                      <a href="/user-like-us">Learn More</a>
                    </button>
                  </div>
                );
              })}
            </div>
          </div>
        </div>
        <div className="foot-print">
          <h1>２０２４ 羽匠足跡｜TRACKING</h1>
          <div className="img-wrapper">
            <img src={footprintDesktop} alt="foot print" className="desktop" />
            <img src={footprintMobile} alt="foot print" className="mobile" />
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default Index;
